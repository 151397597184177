import { getTelegramLink } from 'app/utils'

export const TELEGRAM_LINK_PROVIDER = 'https://t.me'

export class Links {
  // CivSoc
  static readonly CIVSOC = 'https://civsoc.net'
  static readonly REGISTER = 'https://civsoc.net/zayavka-na-vstuplenie'
  static readonly MANIFEST = 'https://civsoc.net/our-manifest'
  static readonly REGULATION = 'https://civsoc.net/ustav-dvizheniya'
  static readonly FRACTIONS = 'https://civsoc.net/frakcii'
  static readonly DESIGNER = 'https://addaald.com'
  // fortress socials and other links
  static readonly FACEBOOK = `https://facebook.com/cs.fortress`
  static readonly VK = `https://vk.com/cs.fortress`
  static readonly INSTAGRAM = `https://instagram.com/cs.fortress`
  static readonly TWITTER = 'https://twitter.com/CsFortress'
  static readonly TELEGRAM = getTelegramLink('FortressCivSocBot')
  static readonly VOLUNTEER = 'https://forms.gle/s8J6CsfvdGh28zTf9'
  static readonly UNSUBSCRIBE = 'https://my.cloudpayments.ru/ru/unsubscribe'
  // partners
  static readonly HUMAN_RIGHTS_OPEN_RUSSIA = 'https://www.facebook.com/lawopr'
  // faq
  static readonly HUMAN_RIGHTS_OPEN_RUSSIA_WEB = 'https://orpravo.org'
  static readonly PEREDERZHKA = 'http://peredergka.ru'
  static readonly NOCHLEZKA = 'https://homeless.ru/'
  static readonly REPORT_NI_ZAKONA_NI_SPRAVEDLIVOSTI =
    'https://nasiliu.net/wp-content/uploads/2016/10/Ni-zakona-ni-spravedlivosti.pdf'

  static readonly REPORT_STANDARTY =
    'https://nasiliu.net/wp-content/uploads/2016/10/2015_Standarty.pdf'

  static readonly VOZ_REPORT =
    'https://www.who.int/news-room/fact-sheets/detail/violence-against-women'

  static readonly VOZ_REPORT_COVID =
    'https://www.who.int/emergencies/diseases/novel-coronavirus-2019/question-and-answers-hub/q-a-detail/violence-against-women-during-covid-19?gclid=CjwKCAjwtqj2BRBYEiwAqfzur9lXzxfvChCdDz0ulrXElW0ipUEBacdnrvpJKhHuyBLewR6TTxX86RoClxEQAvD_BwE'
}
