import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import React, { ReactElement, useCallback } from 'react'
import { Contacts, Links } from 'app/constants'
import Telegram from 'app/images/telegram.svg'
import { getImageSources, getTelLink } from 'app/utils'
import { Button } from '../button'
import { Heading, Paragraph } from '../text'
import './three.scss'

export const SectionThree = (): ReactElement => {
  const {
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
  } = useStaticQuery(graphql`
    query {
      xlImage: file(relativePath: { eq: "backgrounds/mountains-xl.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lgImage: file(relativePath: { eq: "backgrounds/mountains-lg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mdImage: file(relativePath: { eq: "backgrounds/mountains-md.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smImage: file(relativePath: { eq: "backgrounds/mountains-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xsImage: file(relativePath: { eq: "backgrounds/mountains-xs.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = getImageSources({
    type: 'fluid',
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
  })

  const handlePhoneClick = useCallback(() => {
    window.location.href = getTelLink(Contacts.PHONE_ONE.plain)
  }, [])

  const handleTelegramClick = useCallback(() => {
    window.open(Links.TELEGRAM)
  }, [])

  return (
    <div className='section-three'>
      <Image
        className='section-three__background'
        fluid={sources}
        imgStyle={{ objectPosition: '50% 100%' }}
      />
      <div className='section-three__container'>
        <Heading tag='h2' align='center' marginBottom='0'>
          Вы в опасности?
          <br />
          Уходите в безопасное место
        </Heading>
        <Paragraph
          className='section-three__par'
          align='center'
          marginBottom='0'
        >
          Звоните, и наши волонтёры помогут найти вам убежище. Это бесплатно:
          деньги на жильё дают сотни неравнодушных людей по всей России.
        </Paragraph>
        <div className='section-three__interactions'>
          <Button className='section-three__call-us' onClick={handlePhoneClick}>
            {Contacts.PHONE_ONE.formatted}
          </Button>
          <Button
            className='section-three__call-us section-three__call-us--mobile'
            onClick={handlePhoneClick}
          >
            Позвонить
          </Button>
          <Button
            className='section-three__write-us'
            color='blue'
            onClick={handleTelegramClick}
          >
            Написать нам
          </Button>
          <Button
            className='section-three__write-us section-three__write-us--mobile'
            color='blue'
            onClick={handleTelegramClick}
            aria-label='telegram'
          >
            <Telegram />
          </Button>
        </div>
      </div>
    </div>
  )
}
