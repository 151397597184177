import React, { ReactElement } from 'react'
import { Links } from 'app/constants'
import Facebook from 'app/images/facebook.svg'
import Instagram from 'app/images/instagram.svg'
import Twitter from 'app/images/twitter.svg'
import Vk from 'app/images/vk.svg'
import { FloatButton } from '../button'
import './social-panel.scss'

export const SocialPanel = (): ReactElement => (
  <div className='social-panel'>
    <FloatButton
      href={Links.VK}
      target='_blank'
      rel='noopener noreferrer'
      color='white'
      aria-label='VK'
    >
      <Vk />
    </FloatButton>
    <FloatButton
      href={Links.FACEBOOK}
      target='_blank'
      rel='noopener noreferrer'
      color='white'
      aria-label='facebook'
    >
      <Facebook />
    </FloatButton>
    <FloatButton
      href={Links.INSTAGRAM}
      target='_blank'
      rel='noopener noreferrer'
      color='white'
      aria-label='instagram'
    >
      <Instagram />
    </FloatButton>
    <FloatButton
      href={Links.TWITTER}
      target='_blank'
      rel='noopener noreferrer'
      color='white'
      className='social-panel__twitter'
      aria-label='twitter'
    >
      <Twitter />
    </FloatButton>
  </div>
)
