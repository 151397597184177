import classNames from 'classnames'
import React, { ReactElement } from 'react'
import { Paragraph, Heading } from '../text'
import './point.scss'

export interface PointProps {
  title: (string | ReactElement) | (string | ReactElement)[]
  description: string
  color: 'red' | 'blue' | 'none'
  className?: string
}

export const Point = ({
  title,
  description,
  color,
  className,
}: PointProps): ReactElement<HTMLDivElement> => (
  <div className={classNames('point', className)}>
    <span
      className={classNames('point__stroke', {
        'point__stroke--blue': color === 'blue',
        'point__stroke--red': color === 'red',
        'point__stroke--hide': color === 'none',
      })}
    />
    <Heading tag='h3' size='28' lineHeight='medium' align='center'>
      {title}
    </Heading>
    <Paragraph size='18' align='center' marginBottom='0'>
      {description}
    </Paragraph>
  </div>
)
