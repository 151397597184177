import classNames from 'classnames'
import React, { ReactElement } from 'react'
import CivSoc from 'app/images/civsoc.svg'
import Close from 'app/images/close.svg'
import Form from 'app/images/menu-form-1.svg'
import { Navigation } from '../navigation'
import './menu.scss'

interface MenuProps {
  closed?: boolean
  onClose?: () => void
}

export const Menu = ({ closed, onClose }: MenuProps): ReactElement => (
  <div
    className={classNames('header-menu', {
      'header-menu--hidden': closed,
    })}
  >
    <div className='header-menu__form-one'>
      <Form />
    </div>
    <div className='header-menu__top'>
      <div className='header-menu__civsoc'>
        <CivSoc />
      </div>
      <button
        className='header-menu__close'
        onClick={onClose}
        aria-label='close menu'
      >
        <Close />
      </button>
    </div>
    <nav className='header-menu__nav'>
      <Navigation
        itemColors={[null, null, null, null, null]}
        onTransition={onClose}
        menu
      />
    </nav>
  </div>
)
