import React, { ReactElement } from 'react'
import { Links, Contacts } from 'app/constants'
import Call from 'app/images/call.svg'
import Telegram from 'app/images/telegram.svg'
import { getTelLink } from 'app/utils'
import { FloatButton } from '../button'
import './contact-panel.scss'

export const ContactPanel = (): ReactElement => (
  <div className='contact-panel'>
    <FloatButton
      href={getTelLink(Contacts.PHONE_ONE.plain)}
      color='red'
      aria-label='Позвонить'
    >
      <Call />
    </FloatButton>
    <FloatButton
      href={Links.TELEGRAM}
      target='_blank'
      rel='noopener noreferrer'
      color='red'
      aria-label='telegram'
    >
      <Telegram />
    </FloatButton>
  </div>
)
