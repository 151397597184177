import cn from 'classnames'
import React, {
  ReactElement,
  useState,
  useCallback,
  Children,
  cloneElement,
} from 'react'
import './switcher.scss'

interface SwitcherProps {
  children: ReactElement | ReactElement[]
  tabs: ReactElement | ReactElement[]
  className?: string
  classNameTabs?: string
}

export const Switcher = ({
  children,
  tabs,
  className,
  classNameTabs,
}: SwitcherProps): ReactElement => {
  const [active, setActive] = useState(0)
  const onChangeActive = useCallback(
    (index) => {
      setActive(index)
    },
    [setActive]
  )

  return (
    <div className={cn('switcher', className)}>
      <div className={cn('switcher__tabs', classNameTabs)}>
        {Children.map(tabs, (element, index) =>
          cloneElement(element, {
            index,
            active,
            onChange: onChangeActive,
          })
        )}
      </div>
      {Children.map(children, (element, index) =>
        cloneElement(element, {
          index,
          active,
        })
      )}
    </div>
  )
}
