import cn from 'classnames'
import React, { ReactElement, useCallback } from 'react'

interface SwitcherTabProps {
  className?: string
  children: string
  index?: number
  active?: number
  onChange?: (arg?: number) => void
}

export const SwitcherTab = ({
  className,
  children,
  index,
  active,
  onChange,
}: SwitcherTabProps): ReactElement => {
  const handleClick = useCallback(() => {
    if (onChange) {
      onChange(index)
    }
  }, [index, onChange])

  return (
    <button
      className={cn('switcher__tab', className, {
        'switcher__tab--active': active === index,
      })}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}
