import classNames from 'classnames'
import React, { ReactElement } from 'react'

interface ContentProps {
  children: ReactElement | ReactElement[]
  active?: number
  index?: number
}

export const SwitcherContent = ({
  children,
  active,
  index,
}: ContentProps): ReactElement => (
  <div
    className={classNames('switcher__content', {
      'switcher__content--active': active === index,
    })}
  >
    {children}
  </div>
)
