import classNames from 'classnames'
import React, { ReactElement, ButtonHTMLAttributes } from 'react'
import './button.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'red' | 'blue'
}

export const Button = ({
  children,
  className,
  color = 'red',
  ...props
}: ButtonProps): ReactElement => (
  <button
    className={classNames('button', className, [`button--${color}`])}
    {...props}
  >
    {children}
  </button>
)
