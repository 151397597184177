import React, { ReactElement } from 'react'
import { Button } from '../button'
import './fail.scss'

interface DonateFailProps {
  onBack: () => void
  reason?: string
}

export const DonateFail = ({
  onBack,
  reason,
}: DonateFailProps): ReactElement => (
  <div className='donate-fail'>
    <h2 className='donate-fail__title'>Ой...</h2>
    <p className='donate-fail__par'>
      {reason || 'Что-то пошло не так. Пожалуйста, попробуйте снова.'}
    </p>
    <Button className='donate-fail__back-button' onClick={onBack}>
      Назад
    </Button>
  </div>
)
