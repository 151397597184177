import React, { ReactElement, useCallback } from 'react'
import { Links, Contacts } from 'app/constants'
import CivSoc from 'app/images/civsoc.svg'
import Facebook from 'app/images/facebook.svg'
import Instagram from 'app/images/instagram.svg'
import Twitter from 'app/images/twitter.svg'
import Vk from 'app/images/vk.svg'
import { getTelLink } from 'app/utils'
import { Button } from '../button'
import { Navigation } from '../navigation'
import { Paragraph, Heading } from '../text'
import './footer.scss'

export const Footer = (): ReactElement => {
  const handlePhoneOneClick = useCallback(() => {
    window.location.href = getTelLink(Contacts.PHONE_ONE.plain)
  }, [])

  const handlePhoneTwoClick = useCallback(() => {
    window.location.href = getTelLink(Contacts.PHONE_TWO.plain)
  }, [])

  const handleTelegramClick = useCallback(() => {
    window.open(Links.TELEGRAM)
  }, [])

  return (
    <footer className='footer'>
      <div className='footer__container'>
        <div className='footer__links'>
          <section className='footer__civsoc'>
            <Heading
              className='footer__civsoc-heading'
              tag='h3'
              size='28'
              marginBottom='32'
              color='white'
            >
              <span>Гражданское общество</span>
              <CivSoc />
            </Heading>
            <ul>
              <li>
                <Paragraph color='white'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={Links.REGISTER}
                  >
                    Вступить
                  </a>
                </Paragraph>
              </li>
              <li>
                <Paragraph color='white'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={Links.MANIFEST}
                  >
                    Манифест
                  </a>
                </Paragraph>
              </li>
              <li>
                <Paragraph color='white'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={Links.REGULATION}
                  >
                    Устав
                  </a>
                </Paragraph>
              </li>
              <li>
                <Paragraph color='white'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={Links.FRACTIONS}
                  >
                    Фракции
                  </a>
                </Paragraph>
              </li>
            </ul>
          </section>
          <section className='footer__fortress'>
            <Heading tag='h3' size='28' marginBottom='32' color='white'>
              Проект Крепость
            </Heading>
            <Navigation itemColors={[null, null, null, null, null]} footer />
          </section>
        </div>
        <section className='footer__contact'>
          <Heading
            className='footer__phone'
            tag='h3'
            size='32'
            marginBottom='32'
            color='white'
          >
            {Contacts.PHONE_ONE.formatted}
          </Heading>
          <Heading
            className='footer__phone'
            tag='h3'
            size='32'
            marginBottom='32'
            color='white'
          >
            {Contacts.PHONE_TWO.formatted}
          </Heading>
          <Button
            className='footer__phone-button'
            onClick={handlePhoneOneClick}
          >
            {Contacts.PHONE_ONE.formatted}
          </Button>
          <Button
            className='footer__phone-button'
            onClick={handlePhoneTwoClick}
          >
            {Contacts.PHONE_TWO.formatted}
          </Button>
          <Paragraph color='white' className='footer__danger'>
            Если вы в опасности
          </Paragraph>
          <Button className='footer__button' onClick={handleTelegramClick}>
            Написать
          </Button>
        </section>
      </div>
      <div className='footer__interactions'>
        <div className='footer__socials'>
          <a
            href={Links.FACEBOOK}
            target='_blank'
            rel='noopener noreferrer'
            className='footer__facebook'
            aria-label='Facebook'
          >
            <Facebook />
          </a>
          <a
            href={Links.VK}
            target='_blank'
            rel='noopener noreferrer'
            className='footer__vk'
            aria-label='VK'
          >
            <Vk />
          </a>
          <a
            href={Links.INSTAGRAM}
            target='_blank'
            rel='noopener noreferrer'
            className='footer__instagram'
            aria-label='Instagram'
          >
            <Instagram />
          </a>
          <a
            href={Links.TWITTER}
            target='_blank'
            rel='noopener noreferrer'
            className='footer__twitter'
            aria-label='Twitter'
          >
            <Twitter />
          </a>
        </div>
      </div>
      <div className='footer__author'>
        <Paragraph size='16' color='blue-1' marginBottom='0'>
          Дизайн —&nbsp;
          <a target='_blank' rel='noopener noreferrer' href={Links.DESIGNER}>
            Адда Альд
          </a>
        </Paragraph>
      </div>
    </footer>
  )
}
