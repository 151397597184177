import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React, { ReactElement, useCallback } from 'react'
import { Contacts, Links } from 'app/constants'
import Telegram from 'app/images/telegram.svg'
import { getImageSources, getTelLink } from 'app/utils'
import { Button } from '../button'
import { Heading } from '../text'
import './one.scss'

export const SectionOne = (): ReactElement => {
  const {
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
  } = useStaticQuery(graphql`
    query {
      xlImage: file(relativePath: { eq: "backgrounds/fortress-one-xl.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lgImage: file(relativePath: { eq: "backgrounds/fortress-one-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mdImage: file(relativePath: { eq: "backgrounds/fortress-one-md.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smImage: file(relativePath: { eq: "backgrounds/fortress-one-sm.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xsImage: file(relativePath: { eq: "backgrounds/fortress-one-xs.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = getImageSources({
    type: 'fluid',
    xsImage,
    smImage,
    mdImage,
    lgImage,
    xlImage,
  })

  const handlePhoneClick = useCallback(() => {
    window.location.href = getTelLink(Contacts.PHONE_ONE.plain)
  }, [])

  const handleTelegramClick = useCallback(() => {
    window.open(Links.TELEGRAM)
  }, [])

  return (
    <div className='section-one'>
      <Image
        className='section-one__background'
        fluid={sources}
        imgStyle={{ objectPosition: '50% 100%', height: 'auto' }}
        loading='eager'
      />
      <section className='section-one__container'>
        <Heading tag='h2' marginBottom='0'>
          Вы в опасности?
          <br />
          Мы поможем укрыться от домашнего насилия.
        </Heading>
        <div className='section-one__interactions'>
          <Button
            className='section-one__button section-one__phone'
            onClick={handlePhoneClick}
          >
            {Contacts.PHONE_ONE.formatted}
          </Button>
          <Button className='section-one__button' onClick={handleTelegramClick}>
            Написать в Телеграм
          </Button>
          <Button
            className='section-one__button section-one__mobile section-one__telegram'
            onClick={handleTelegramClick}
            aria-label='telegram'
          >
            <Telegram />
          </Button>
        </div>
      </section>
    </div>
  )
}
