import React, { ReactElement } from 'react'
import { Sections } from 'app/constants'
import FormOne from 'app/images/section-two-form-1.svg'
import FormTwo from 'app/images/section-two-form-2.svg'
import { Point, PointProps } from '../point'
import { Heading, Paragraph } from '../text'
import './two.scss'

const points: PointProps[] = [
  {
    title: 'Эвакуация',
    description:
      'Заселим в наше временное убежище либо поможем найти доступный вам кризисный центр',
    color: 'red',
  },
  {
    title: 'Юридическая помощь',
    description:
      'Проконсультируем по юридическим аспектам того, как можно защитить вас и ваших близких от домашнего насилия',
    color: 'blue',
  },
  {
    title: 'Психологическая помощь',
    description:
      'Предоставим психологов с опытом работы с пострадавшими от домашнего насилия либо направим к специалистам из других некоммерческих организаций',
    color: 'blue',
  },
  {
    title: 'А также',
    description:
      'Формируем в обществе правильные взгляды на проблему домашнего насилия и насилия в целом',
    color: 'blue',
  },
]

export const SectionTwo = (): ReactElement => (
  <div className='section-two'>
    <div className='section-two__form-one'>
      <FormOne />
    </div>
    <div className='section-two__form-two'>
      <FormTwo />
    </div>
    <section id={Sections.ABOUT_PROJECT} className='section-two__sub-one'>
      <Heading tag='h2'>Крепость —</Heading>
      <Paragraph>
        проект Гражданского общества, призванный обеспечить убежище людям,
        страдающим от домашнего насилия или насилия, связанного с их
        идентичностью.
      </Paragraph>
      <Paragraph>
        Цель проекта — создать гражданскую инфраструктуру реагирования на случаи
        домашнего насилия. Частью этой инфраструктуры должны стать волонтёры,
        помогающие жертвам лично и/или в сборе средств на убежище, активисты и
        их медиаресурсы, а также некоммерческие организации и иные гражданские
        инициативы.
      </Paragraph>
      <Paragraph>
        Мы намерены создать независимую от государства волонтёрскую сеть, быстро
        реагирующую на случаи домашнего насилия, чтобы организовать убежища или
        эвакуацию тех, кто в ней нуждается, и оказать им дальнейшую помощь.
      </Paragraph>
    </section>
    <section id={Sections.HOW_ARE_WE_HELPING} className='section-two__sub-two'>
      <Heading tag='h2'>Поможем всем, кому угрожает опасность</Heading>
      <Paragraph>
        Всем гражданам России, жизни и здоровью которых угрожает опасность в
        связи с физическим насилием, которому они подвергаются у себя дома. Пока
        работаем только в Москве.
      </Paragraph>
      <Paragraph>
        Укроем вас на неделю, и за это время поможем прийти в себя, сведём со
        всеми необходимыми службами — психологом, юристами, — и постараемся
        найти долгосрочное решение.
      </Paragraph>
    </section>
    <section className='section-two__sub-three'>
      {points.map(({ title, description, color }) => (
        <Point
          key={title.toString()}
          title={title}
          description={description}
          color={color}
        />
      ))}
    </section>
  </div>
)
