import React, { ReactElement, useState, useCallback, ChangeEvent } from 'react'
import { Sections, Links, Contacts } from 'app/constants'
import HumanRightsOpenRussia from 'app/images/human-rights-open-russia.svg'
import FormOne from 'app/images/section-four-form-1.svg'
import FormTwo from 'app/images/section-four-form-2.svg'
import FormThree from 'app/images/section-four-form-3.svg'
import { getEmailLink } from 'app/utils'
import { Button } from '../button'
import { DonateModal } from '../donate'
import { Input } from '../input'
import { Heading, Paragraph } from '../text'
import './four.scss'

export const SectionFour = (): ReactElement => {
  const [isDonationOpen, setDonationOpen] = useState(false)
  const [donation, setDonation] = useState('500 ₽')

  const handleInputFocus = useCallback(() => {
    setDonation(donation.slice(0, donation.length - 2))
  }, [donation, setDonation])

  const handleInputBlur = useCallback(() => {
    setDonation(donation.concat(' ₽'))
  }, [donation, setDonation])

  const handleInputChange = useCallback(
    ({ nativeEvent, target }: ChangeEvent<HTMLInputElement>) => {
      const e = nativeEvent as CompositionEvent & InputEvent

      if (e.inputType.search('insert') !== -1 && (+e.data || e.data === '0')) {
        setDonation(target.value)
      } else if (e.inputType.search('delete') !== -1) {
        setDonation(target.value)
      }
    },
    [setDonation]
  )

  const handleDonateClick = useCallback(() => {
    if (!isDonationOpen) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }
    setDonationOpen(!isDonationOpen)
  }, [isDonationOpen, setDonationOpen])

  const handleVolunteerClick = useCallback(() => {
    window.open(Links.VOLUNTEER)
  }, [])

  return (
    <div className='section-four'>
      <div className='section-four__form-one'>
        <FormOne />
      </div>
      <div className='section-four__form-two'>
        <FormTwo />
      </div>
      <div className='section-four__form-three'>
        <FormThree />
      </div>
      <section id={Sections.WANNA_HELP} className='section-four__container'>
        <Heading tag='h2' align='center' className='section-four__heading'>
          Крепость — это вы
        </Heading>
        <Paragraph align='center' marginBottom='0'>
          Только вместе мы можем спасти тех, кому угрожает опасность.
        </Paragraph>
        <Paragraph align='center' marginBottom='0'>
          Поддержите проект, чтобы помочь всем гражданам России, жизнь и
          здоровье которых находятся под угрозой в связи с физическим насилием,
          которому они подвергаются у себя дома.
        </Paragraph>
        <div className='section-four__interactions'>
          <label
            htmlFor='donate-input'
            style={{
              opacity: '0',
              position: 'absolute',
              pointerEvents: 'none',
            }}
          >
            Пожертвовать
          </label>
          <Input
            id='donate-input'
            type='text'
            className='section-four__input'
            value={donation}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleInputChange}
          />
          <Button color='blue' onClick={handleDonateClick}>
            Пожертвовать
          </Button>
          <Button onClick={handleVolunteerClick}>Стать волонтёром</Button>
        </div>
        <div>
          <Heading
            tag='h2'
            align='center'
            className='section-four__partners-heading'
          >
            Партнёры
          </Heading>
          <Paragraph
            align='center'
            marginBottom='0'
            className='section-four__partners-description'
          >
            Хотите стать партнёром? Пишите на&nbsp;
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={getEmailLink(Contacts.EMAIL)}
            >
              {Contacts.EMAIL}
            </a>
          </Paragraph>
        </div>
      </section>
      <DonateModal
        isOpen={isDonationOpen}
        onClose={handleDonateClick}
        initialDonation={donation}
      />
    </div>
  )
}
