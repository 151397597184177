import React, { ReactElement } from 'react'
import { Footer } from '../footer'
import { Header } from '../header'
import { ItemColors } from '../navigation'
import { ContactPanel } from './contact-panel'
import { SocialPanel } from './social-panel'

interface LayoutProps {
  children: ReactElement | ReactElement[]
  headerColors: ItemColors
}

export const Layout = ({
  children,
  headerColors,
}: LayoutProps): ReactElement => (
  <>
    <Header colors={headerColors} />
    {children}
    <Footer />
    <SocialPanel />
    <ContactPanel />
  </>
)
