import { FluidObject } from 'gatsby-image'
/* eslint-disable @typescript-eslint/no-explicit-any */

interface ImageSourcesProps {
  type: 'fluid' | 'fixed'
  xsImage: any
  smImage: any
  mdImage: any
  lgImage: any
  xlImage: any
}

export const getImageSources = ({
  type,
  xsImage,
  smImage,
  mdImage,
  lgImage,
  xlImage,
}: ImageSourcesProps): FluidObject[] => [
  {
    ...xsImage.childImageSharp[type],
    media: `(max-width: 639px)`,
  },
  {
    ...smImage.childImageSharp[type],
    media: `(min-width: 640px) and (max-width: 767px)`,
  },
  {
    ...mdImage.childImageSharp[type],
    media: `(min-width: 768px) and (max-width: 1023px)`,
  },
  {
    ...lgImage.childImageSharp[type],
    media: `(min-width: 1024px) and (max-width: 1279px)`,
  },
  {
    ...xlImage.childImageSharp[type],
    media: `(min-width: 1280px)`,
  },
]
