interface Phone {
  formatted: string
  plain: string
}

export class Contacts {
  static readonly PHONE_ONE: Phone = {
    formatted: '8 800 302 71 21',
    plain: '88003027121',
  }

  static readonly PHONE_TWO: Phone = {
    formatted: '8 495 127 78 73',
    plain: '84951277873',
  }

  static readonly EMAIL = 'cs_fortress@protonmail.com'

  static readonly SVETOV_EMAIL = 'msvetov@gmail.com'
  static readonly SVETOV_TELEGRAM = 'mr_libertarian'
  static readonly ALD_TELEGRAM = 'addaald'
}
