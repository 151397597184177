import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import React, { ReactElement, useCallback, useState } from 'react'
import { Pages, Sections } from 'app/constants'
import { DonateState } from 'app/enums'
import { getImageSources } from 'app/utils'
import { Button } from '../button'
import { Modal } from '../modal'
import { Point } from '../point'
import { Heading, Paragraph } from '../text'
import { DonateBar } from './donate-bar'
import { DonateFail } from './fail'
import { DonateInputs } from './inputs'
import { DonateSuccess } from './success'
import './donate.scss'

interface CloudPayments {
  recurrent: {
    interval: 'Day' | 'Week' | 'Month'
    period: number
    maxPeriods?: number
    amount?: number
    startDate?: string
    customerReceipt?: string
  }
}

export interface DonateForm {
  name?: string
  lastName?: string
  phone?: string
  email: string
  amount: string
  cloudPayments?: CloudPayments
  recurrent?: boolean
}

export const Donate = (): ReactElement => {
  const {
    xlImage,
    lgImage,
    mdImage,
    smImage,
    xsImage,
    site: {
      siteMetadata: { donateGoal },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          donateGoal {
            goal
            current
            operations
            date
          }
        }
      }
      xlImage: file(relativePath: { eq: "backgrounds/donate-xl.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lgImage: file(relativePath: { eq: "backgrounds/donate-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      mdImage: file(relativePath: { eq: "backgrounds/donate-md.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      smImage: file(relativePath: { eq: "backgrounds/donate-sm.png" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xsImage: file(relativePath: { eq: "backgrounds/donate-xs.png" }) {
        childImageSharp {
          fluid(maxWidth: 640, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const sources = getImageSources({
    type: 'fluid',
    xsImage,
    smImage,
    mdImage,
    lgImage,
    xlImage,
  })

  const [state, setState] = useState<DonateState>(DonateState.InProgress)
  const [errorReason, setErrorReason] = useState()

  const onSuccess = useCallback(() => {
    setState(DonateState.Success)
  }, [])
  const onFail = useCallback((reason) => {
    setErrorReason(reason)
    setState(DonateState.Fail)
  }, [])
  const handleBack = useCallback(() => {
    setState(DonateState.InProgress)
    setErrorReason(undefined)
  }, [])

  const onSupport = useCallback(() => {
    window.location.href = `${Pages.DONATE}/#${Sections.DONATE}`
  }, [])

  return (
    <div className='donate'>
      <Image
        className='donate__background'
        fluid={sources}
        imgStyle={{ objectPosition: '50% 100%', height: 'auto' }}
        loading='eager'
      />
      <div className='donate__container'>
        <Heading
          id={Sections.DONATE}
          tag='h2'
          align='center'
          className='donate__heading'
        >
          Вместе мы — сила
        </Heading>
        <DonateInputs
          className='donate__switcher'
          onSuccess={onSuccess}
          onFail={onFail}
          theme='dark'
        />
        <Modal onClose={handleBack} isOpen={state !== DonateState.InProgress}>
          <div className='donate__modal'>
            {state === DonateState.Success && <DonateSuccess />}
            {state === DonateState.Fail && (
              <DonateFail onBack={handleBack} reason={errorReason} />
            )}
          </div>
        </Modal>
        <div className='donate__spendings'>
          <Heading
            className='donate__spendings-heading'
            tag='h2'
            align='center'
            size='48'
          >
            На что конкретно пойдут ваши средства
          </Heading>
          <div className='donate__spending-points'>
            <Point
              color='none'
              title={['Помещение', <br key='safespace' />, '190 000 ₽']}
              description='Это минимум, необходимый проекту для оплаты хостела. В нём мы можем разместить до 20 человек единовременно'
            />
            <Point
              color='none'
              title={['Охрана', <br key='security' />, '30 000 ₽']}
              description='До 30 тысяч будет уходить на оплату охраны для обеспечения безопасности наших подопечных'
            />
            <Point
              color='none'
              title={['Колл-центр', <br key='call-center' />, '2 000 ₽']}
              description='Для обеспечения технической составляющей колл-центра, принимающего заявки от жертв насилия'
            />
            <Point
              color='none'
              title={[
                'Срочные расходы',
                <br key='urgent-expenses' />,
                '30 000 ₽',
              ]}
              description='Это сумма поможет нашим подопечным при необходимости оставаться на связи, безопасно добраться до убежища, купить лекарства — словом, покрыть непредвиденные расходы'
            />
            <Point
              color='none'
              title={['Информирование', <br key='informing' />, '30 000 ₽']}
              description='С помощью этих средств мы сможем эффективно доносить до людей, особенно до жертв насилия, информацию о Крепости и недопустимости домашнего насилия'
            />
            <Point
              className='donate__summary'
              color='none'
              title={[
                'Итого',
                <br key='informing' />,
                `${donateGoal.goal.toLocaleString('ru')} ₽`,
              ]}
              description='Эта сумма покроет расходы работы «Крепости» в Москве за один месяц'
            />
          </div>
        </div>
        {/* <Heading tag='h2' align='center' size='48' marginBottom='0'>
          Сколько уже собрали
        </Heading>
        <div className='donate__donate-bar'>
          <DonateBar
            goal={donateGoal.goal}
            current={donateGoal.current}
            operations={donateGoal.operations}
            date={donateGoal.date}
          />
        </div> */}
        <div className='donate__goals'>
          <Heading tag='h2' align='center' size='48' marginBottom='0'>
            Крепость в регионах
          </Heading>
          <Paragraph className='donate__goal' align='center'>
            Цель «Крепости» ー создать волонтёрскую сеть по всей стране. И уже
            сейчас мы имеем примерный расчёт того, какое количество средств нам
            необходимо для расширения географии проекта.
          </Paragraph>
          <div className='donate__goal-points'>
            <Point
              color='none'
              title={['Санкт-Петербург', <br key='spb' />, '200 000 ₽']}
              description='200 тысяч рублей в месяц необходимо, чтобы запустить проект в северной столице'
            />
            <Point
              color='none'
              title={['«Опасные» регионы', <br key='spb' />, 'по 250 000 ₽']}
              description='По 250 тысяч рублей в месяц необходимо, чтобы расширить деятельность проекта на Дагестан, Чечню, Северную Осетию и другие регионы, где риски стать жертвой домашнего насилия высоки'
            />
          </div>
          <Paragraph className='donate__call-to-action' align='center'>
            Давайте решать проблему домашнего насилия вместе.
          </Paragraph>
          <Button className='donate__support' color='blue' onClick={onSupport}>
            Поддержать
          </Button>
        </div>
      </div>
    </div>
  )
}
