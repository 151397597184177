import classNames from 'classnames'
import React, { ReactElement, AnchorHTMLAttributes } from 'react'
import './float.scss'

interface FloatProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color: 'white' | 'red'
}

export const FloatButton = ({
  children,
  className,
  color = 'red',
  ...props
}: FloatProps): ReactElement => (
  <a className={classNames('float', className, [`float--${color}`])} {...props}>
    {children}
  </a>
)
