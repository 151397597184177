import classNames from 'classnames'
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  forwardRef,
} from 'react'
import './input.scss'

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, id, ...props }, ref) => (
    <input
      ref={ref}
      id={id}
      className={classNames('input', className)}
      {...props}
    />
  )
)

Input.displayName = 'Input'
