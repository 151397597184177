import { Link } from 'gatsby'
import React, { ReactElement, useCallback, useState } from 'react'
import { Pages } from 'app/constants'
import { DonateState } from 'app/enums'
import Form from 'app/images/donate-form-1.svg'
import { Modal } from '../modal'
import { DonateFail } from './fail'
import { DonateInputs } from './inputs'
import { DonateSuccess } from './success'
import './donate-modal.scss'

interface DonateModalProps {
  isOpen: boolean
  initialDonation?: string
  onClose: () => void
}

export const DonateModal = ({
  isOpen,
  onClose,
  initialDonation,
}: DonateModalProps): ReactElement => {
  const [state, setState] = useState<DonateState>(DonateState.InProgress)
  const [errorReason, setErrorReason] = useState()

  const onSuccess = useCallback(() => {
    setState(DonateState.Success)
  }, [])
  const onFail = useCallback((reason) => {
    setErrorReason(reason)
    setState(DonateState.Fail)
  }, [])
  const handleBack = useCallback(() => {
    setState(DonateState.InProgress)
    setErrorReason(undefined)
  }, [])

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className='donate-modal'>
        <Link
          to={Pages.DONATE}
          onClick={onClose}
          className='donate-modal__full-version'
        >
          Переход к полной версии
        </Link>
        <div className='donate-modal__svg-form'>
          <Form />
        </div>
        <div className='donate-modal__container'>
          {state === DonateState.InProgress && (
            <>
              <h2 className='donate-modal__title'>
                Оформите регулярное пожертвование — так Крепость будет
                устойчивее
              </h2>
              <DonateInputs
                onSuccess={onSuccess}
                onFail={onFail}
                initialDonation={initialDonation}
                theme='light'
              />
            </>
          )}
          {state === DonateState.Success && <DonateSuccess />}
          {state === DonateState.Fail && (
            <DonateFail onBack={handleBack} reason={errorReason} />
          )}
        </div>
      </div>
    </Modal>
  )
}
