import React, { ReactElement, useState, useCallback } from 'react'
import { Pages } from 'app/constants'
import Burger from 'app/images/burger.svg'
import Logo from 'app/images/civsoc-logo.svg'
import CivSoc from 'app/images/civsoc.svg'
import { Navigation, ItemColors } from '../navigation'
import { Menu } from './menu'
import './header.scss'

interface HeaderProps {
  colors: ItemColors
}

export const Header = ({ colors }: HeaderProps): ReactElement => {
  const [closed, setClosed] = useState(true)

  const handleMenu = useCallback(() => {
    if (closed) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = ''
    }
    setClosed(!closed)
  }, [closed, setClosed])

  return (
    <>
      <header className='header'>
        <a
          href={Pages.INDEX}
          className='header__logo'
          aria-label='Гражданское общество'
        >
          <Logo />
        </a>
        <a
          href={Pages.INDEX}
          className='header__civsoc'
          aria-label='Гражданское общество'
        >
          <CivSoc />
        </a>
        <button
          className='header__burger'
          onClick={handleMenu}
          aria-label='menu'
        >
          <Burger />
        </button>
        <nav className='header__nav'>
          <Navigation header itemColors={colors} />
        </nav>
      </header>
      <Menu closed={closed} onClose={handleMenu} />
    </>
  )
}
