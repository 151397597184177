import React, { ReactElement } from 'react'
import { getEmailLink, getTelegramLink } from 'app/utils'
import { Contacts } from './contacts'
import { Links } from './links'

export interface QA {
  question: string
  answer: string | (string | ReactElement)[]
}

interface Block {
  title: string
  questions: QA[]
}

export class FAQ {
  static readonly structure: Block = {
    title: 'О структуре и организации "Крепости"',
    questions: [
      {
        question: 'Кто вы такие?',
        answer: [
          'Мы — активисты движения ',
          <a
            href={Links.CIVSOC}
            target='_blank'
            rel='noopener noreferrer'
            key='civsoc'
          >
            &quot;Гражданское общество&quot;
          </a>,
          '. Из-за роста домашнего насилия во время пандемии, а также потому, что проблему домашнего насилия надо решать, мы запустили проект "Крепость". Повестка движения общегражданская и не относится к какому-либо конкретному политическому направлению.',
        ],
      },
      {
        question: 'Где вы находитесь территориально?',
        answer:
          'Место убежища мы держим в секрете, чтобы обеспечить анонимность и безопасность всем обратившимся за помощью. Оно находится внутри третьего транспортного кольца.',
      },
      {
        question: 'Вы работаете только в Москве, или в регионах тоже?',
        answer:
          'Пока мы предоставляем временное убежище только в Москве, а юридическую и психологическую консультацию — по всей стране. Постепенно мы планируем расшириться на всю Россию.',
      },
      {
        question: 'Вы планируете работать в других регионах?',
        answer:
          'Да. Мы будем расширяться, и для этого нужны ресурсы и средства. Мы только что открыли "Крепость" в Москве, следующий город — Санкт-Петербург. Затем — Чечня, Дагестан и Осетия.',
      },
    ],
  }

  static readonly affected: Block = {
    title: 'Пострадавшим от домашнего насилия',
    questions: [
      {
        question: 'Кому вы помогаете?',
        answer:
          'Мы помогаем всем гражданам РФ, страдающим от домашнего насилия, угрожающего их  жизни и здоровью. Большинство жертв ー женщины, но для нас пол и гендер не важен.',
      },
      {
        question:
          'Вы просто убежище или кризисный центр? Что я получу, если обращусь к вам?',
        answer:
          'Мы убежище и кризисный центр. Мы предоставляем временное жильё, юридические консультации и связываем с психологом.',
      },
      {
        question:
          'Мне нужна регистрация или прописка, чтобы воспользоваться вашими услугами?',
        answer: 'Нет, не нужна.',
      },
      {
        question: 'Как вы эвакуируете людей?',
        answer:
          'Если у пострадавших есть возможность приехать самим, мы встречаем их в условленном месте. В ином случае вызываем такси до места временного убежища.',
      },
      {
        question: 'Я в Подмосковье. Можете помочь?',
        answer:
          'Юридически и психологически мы можем помочь вам  дистанционно. Если вы решите обратиться в суд, окажем полное юридическое сопровождение делу. Можем помочь добраться до убежища, если вы не можете сделать это самостоятельно.',
      },
      {
        question:
          'Хорошо, я позвонил/а, и вы заселили меня во временное жильё. Что дальше?',
        answer:
          'К каждой ситуации мы подходим индивидуально. Вас примут наши волонтёры, а потом поговорят юристы и психологи. Мы поможем вам вернуться к нормальной жизни, а затем несколько раз проверим, что у вас всё в порядке.',
      },
      {
        question: 'Каковы правила нахождения в убежище?',
        answer: [
          'У нашего убежища минимальные правила, так как мы полагаемся на благоразумие наших подопечных и не хотим ограничивать их без крайней нужды.',
          <br key='one' />,
          '1. Не раскрывайте адрес «Крепости» никому: ни семье, ни друзьям, ни партнерам, ни журналистам. Вообще никому. Нам важно, чтобы абьюзер не мог вас найти. Гости запрещены: не приглашайте их в убежище.',
          <br key='two' />,
          '2. Не употребляйте алкоголь и наркотические вещества, не курите на территории убежища.',
          <br key='three' />,
          '3. Ведите себя вежливо и тактично с другими подопечными.',
          <br key='four' />,
          '4. Бережно относитесь к имуществу и помещению убежища. Не занимайтесь вандализмом.',
          <br key='five' />,
          '5. Соблюдайте сроки и не затягивайте выполнение плана действий. Если вы приняли помощь юристов, следуйте их указаниям, получайте справки, документы и активно участвуйте в своей судьбе.',
          <br key='six' />,
          '6. После 23:00 вы должны находиться в убежище — кроме экстренных случаев и работы в ночную смену.',
          <br key='seven' />,
          '7. Не покидайте убежище более чем на 12 часов, не предупредив команду «Крепости». Если вы планируете отсутствовать по уважительной причине более длительный срок, обсудите это с координатором.',
          <br key='eight' />,
          '8. Если вы хотите покинуть «Крепость» насовсем, оповестите вашего координатора и договоритесь с ним о времени и дате выезда.',
          <br key='nine' />,
          '9. С 23:00 до 6:00 находитесь в своих комнатах, а кухню используйте только для приготовления пищи. Вы можете ходить в гости к соседу, но при этом соблюдайте тишину, разговаривая вполголоса.',
          <br key='ten' />,
          '10. Соблюдайте чистоту в своей комнате, убирайте и мойте за собой посуду, санузел. Кроме того, в «Крепости» действует график дежурств — каждый подопечный по расписанию убирает общие пространства (моет полы, раковину, столешницы, санузел с чистящим средством, и т.д.).',
          <br key='eleven' />,
          '11. Каждый готовит себе еду и убирается самостоятельно.',
          <br key='twelve' />,
          '12. Не заводите романтические отношения с другими подопечными, пока находитесь в убежище.',
          <br key='thirteen' />,
          '13. Если вы заметили, что кто-то из других подопечных нарушает правила, немедленно сообщите координатору.',
        ],
      },
      {
        question: 'Как долго я могу жить в «Крепости»?',
        answer:
          'Срок вашего нахождения в «Крепости» устанавливается индивидуально. Он составляет 10 дней, если плана действий не было. Максимальный срок нахождения в «Крепости» — три месяца. За это время вы должны выполнить план либо минимальные требования по обеспечению себя работой и жильем. В исключительном случае координаторы могут продлить срок вашего пребывания.',
      },
      {
        question: 'Мне нужно платить за ваши услуги?',
        answer: 'Нет.',
      },
      {
        question: 'Питание включено? ',
        answer:
          'Да, включено. Каждую неделю волонтёры закупают и привозят базовый набор продуктов, по возможности учитывая пожелания жильцов.',
      },
      {
        question:
          'Какие специалисты оказывают помощь подопечным и какая у них квалификация?',
        answer: [
          'Психологическую помощь у нас пока оказывает одна специалистка. Она окончила психологический факультет РГГУ и обладает достаточной квалификацией для работы с жертвами насилия.',
          <br key='one' />,
          'Юридическую помощь осуществляют гражданские правозащитные проекты.',
        ],
      },
      {
        question: 'Какую юридическую помощь вы можете оказать?',
        answer:
          'Консультацию с юристом. Если вы решите обратиться в полицию, чтобы зафиксировать факт насилия, или в суд, чтобы начать бракоразводный процесс, вам понадобится помощь специалиста. Мы поможем довести дело до конца, если вы в опасности.',
      },
      {
        question: 'Вы напишете за меня заявление в полицию?',
        answer:
          'Нет. Важно, чтобы вы не боялись обращаться за помощью. Мы вам поможем и объясним, что делать, но составлять и писать заявление будете вы.',
      },
      {
        question:
          'У меня есть ребёнок, без него я не могу уйти от угрозы. Вы поможете?',
        answer:
          'Мы примем вас вместе с вашим ребёнком. Однако государство запрещает частным НКО напрямую работать с детьми. Мы заселяем пострадавших, являющихся опекунами детей, но каждую ситуацию рассматриваем индивидуально.',
      },
      {
        question: 'У вас есть ограничение по возрасту?',
        answer: [
          'Мы принимаем людей старше 18 лет. По закону негосударственным организациям нельзя работать с детьми. Если вам нет 18-ти и вы нуждаетесь в помощи, согласно закону вы должны обратиться к взрослому родственнику, которому можете доверять.',
          <br key='one' />,
          'Есть телефон государственной горячей линии, по которому вас перенаправят в службу опеки. Но нужно понимать возможные последствия, вплоть до лишения родителей родительских прав.',
        ],
      },
      {
        question:
          'У меня есть домашнее животное. Если я уйду, насильник ему навредит или убьёт. Вы берёте с животными? И если нет, что делать?',
        answer:
          'Мы не принимаем в убежище животных. Вы можете отдать вашего питомца на передержку друзьям или родственникам, либо отдать его в зоогостиницу.',
      },
      {
        question:
          'Я оказалась в трудной ситуации и лишилась жилья. Можно переночевать в Крепости?',
        answer: [
          'К сожалению, мы не можем предоставить кров всем нуждающимся, мы помогаем только жертвам домашнего насилия. Пожалуйста, обратитесь в ',
          <a
            key='nochlezhka'
            href={Links.NOCHLEZKA}
            target='_blank'
            rel='noopener noreferrer'
          >
            &quot;Ночлежку&quot;
          </a>,
          ': +7 915 380 26 17 (Москва).',
        ],
      },
    ],
  }

  static readonly helpers: Block = {
    title: 'Тем, кто хочет помочь',
    questions: [
      {
        question:
          'Я хочу организовать убежище в своём городе. Что для этого нужно сделать?',
        answer: [
          'Мы собираемся расширяться на другие города и регионы, так что вы сможете присоединиться к нам. Пишите на почту ',
          <a
            key='svetov-email'
            target='_blank'
            rel='noopener noreferrer'
            href={getEmailLink(Contacts.SVETOV_EMAIL)}
          >
            {Contacts.SVETOV_EMAIL}
          </a>,
          ' или в Телеграм ',
          <a
            key='svetov-telegram'
            target='_blank'
            rel='noopener noreferrer'
            href={getTelegramLink(Contacts.SVETOV_TELEGRAM)}
          >
            {Contacts.SVETOV_TELEGRAM}
          </a>,
          ' и оставайтесь на связи! Как только у нас будет достаточно ресурсов для расширения, мы займёмся организацией убежища в вашем городе.',
        ],
      },
      {
        question: 'Какую подготовку проходят ваши волонтёры?',
        answer: [
          'Мы опираемся на международный опыт и общепринятые стандарты работы с жертвами насилия. Эти стандарты соответствуют изложенным в ',
          <a
            key='report-one'
            href={Links.REPORT_NI_ZAKONA_NI_SPRAVEDLIVOSTI}
            target='_blank'
            rel='noopener noreferrer'
          >
            докладе Национальной независимой комиссии по правам женщин
          </a>,
          ', а также ',
          <a
            key='report-two'
            href={Links.REPORT_STANDARTY}
            target='_blank'
            rel='noopener noreferrer'
          >
            Стандартам деятельности по предоставлению услуг пострадавшим от
            домашнего насилия и организации системы реагирования на случаи
            насилия
          </a>,
          ' центра "АННА".',
          <br key='one' />,
          'Все наши волонтёры ознакомлены с ',
          <a
            key='report-three'
            href={Links.VOZ_REPORT}
            target='_blank'
            rel='noopener noreferrer'
          >
            материалами ВОЗ
          </a>,
          ' о насилии в отношении женщин, в том числе с ',
          <a
            key='report-four'
            href={Links.VOZ_REPORT_COVID}
            target='_blank'
            rel='noopener noreferrer'
          >
            рекомендациями, связанными с коронавирусом
          </a>,
          '. Кроме того, они уже имеют опыт работы с непростыми случаями.',
        ],
      },
      {
        question: 'Как я могу помочь?',
        answer: [
          'Вы можете пожертвовать деньги на нашу работу. Вы можете стать волонтёром: работать в нашем колл-центре, подвозить продукты питания и решать бытовые проблемы. А ещё расскажите о нас в соцсетях и подпишитесь на наши аккаунты в ',
          <a
            key='instagram'
            href={Links.INSTAGRAM}
            target='_blanc'
            rel='noopener noreferrer'
          >
            Instagram
          </a>,
          ', ',
          <a
            key='facebook'
            href={Links.FACEBOOK}
            target='_blanc'
            rel='noopener noreferrer'
          >
            Facebook
          </a>,
          ', ',
          <a
            key='twitter'
            href={Links.TWITTER}
            target='_blanc'
            rel='noopener noreferrer'
          >
            Twitter
          </a>,
          ' и ',
          <a key='vk' href={Links.VK} target='_blanc' rel='noopener noreferrer'>
            Вконтакте
          </a>,
          '. Очень важно создать в обществе консенсус о недопустимости насилия, о том, что обращаться за помощью не стыдно, что защищать себя необходимо. Не молчите.',
        ],
      },
      {
        question:
          'Как я могу убедиться, что мои пожертвования действительно пойдут на благое дело?',
        answer:
          'Раз в квартал мы публикуем отчёты о своей работе, в том числе финансовые. Кроме того, каждое воскресенье мы выпускаем промежуточные посты-отчёты о том, как прошла неделя.',
      },
    ],
  }
}
