import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import React, { useCallback, ReactElement, KeyboardEvent } from 'react'
import Copy from 'app/images/copy.svg'
import { Heading } from '../text'
import './copy-element.scss'

interface CopyElementProps {
  children: string
  title: string
  theme: 'light' | 'dark'
}

export const CopyElement = ({
  children,
  title,
  theme,
}: CopyElementProps): ReactElement => {
  const onCopy = useCallback(() => {
    copy(children)
  }, [children])

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        onCopy()
      }
    },
    [onCopy]
  )

  return (
    <div className='copy-element'>
      <Heading
        className={classNames('copy-element__heading', {
          'copy-element__heading--light': theme === 'light',
          'copy-element__heading--dark': theme === 'dark',
        })}
        tag='h3'
        size='24'
        color='white'
        marginBottom='0'
      >
        {title}
      </Heading>
      <div
        role='button'
        tabIndex={0}
        className={classNames('copy-element__text', {
          'copy-element__text--light': theme === 'light',
          'copy-element__text--dark': theme === 'dark',
        })}
        onClick={onCopy}
        onKeyDown={onEnter}
      >
        {children}
        <div
          className={classNames('copy-element__copy', {
            'copy-element__copy--light': theme === 'light',
            'copy-element__copy--dark': theme === 'dark',
          })}
        >
          <Copy />
        </div>
      </div>
    </div>
  )
}
