import classNames from 'classnames'
import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { Sections, Pages } from 'app/constants'
import './navigation.scss'

type Colors = 'white' | null

export type ItemColors = [Colors, Colors, Colors, Colors, Colors]

interface NavigationProps {
  className?: string
  footer?: boolean
  header?: boolean
  menu?: boolean
  itemColors: ItemColors
  onTransition?: () => void
}

export const Navigation = ({
  className,
  footer,
  header,
  menu,
  itemColors,
  onTransition,
}: NavigationProps): ReactElement => (
  <ul
    className={classNames('navigation', className, {
      'navigation--footer': footer,
      'navigation--header': header,
      'navigation--menu': menu,
    })}
  >
    <li
      className={classNames('navigation__item', {
        'navigation__item--white': itemColors[0] === 'white',
      })}
    >
      <Link to={`/#${Sections.ABOUT_PROJECT}`} onClick={onTransition}>
        О проекте
      </Link>
    </li>
    <li
      className={classNames('navigation__item', {
        'navigation__item--white': itemColors[1] === 'white',
      })}
    >
      <Link to={`/#${Sections.HOW_ARE_WE_HELPING}`} onClick={onTransition}>
        Как помогаем
      </Link>
    </li>
    <li
      className={classNames('navigation__item', {
        'navigation__item--white': itemColors[2] === 'white',
      })}
    >
      <Link to={Pages.DONATE} onClick={onTransition}>
        Хочу помочь
      </Link>
    </li>
    <li
      className={classNames('navigation__item', {
        'navigation__item--white': itemColors[3] === 'white',
      })}
    >
      <Link to={Pages.CONTACTS} onClick={onTransition}>
        Для прессы
      </Link>
    </li>
    <li
      className={classNames('navigation__item', {
        'navigation__item--white': itemColors[4] === 'white',
      })}
    >
      <Link to={Pages.FAQ} onClick={onTransition}>
        FAQ
      </Link>
    </li>
  </ul>
)
