import cn from 'classnames'
import React, { ReactElement } from 'react'
import { Requisites } from 'app/constants'
import { Switcher, SwitcherTab, SwitcherContent } from '../switcher'
import { Paragraph } from '../text'
import { CopyElement } from './copy-element'
import './inputs.scss'

interface DonateInputsProps {
  className?: string
  initialDonation?: string
  theme: 'dark' | 'light'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess: (options: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFail: (reason: string, options: any) => void
}

export const DonateInputs = ({
  className,
  theme,
}: DonateInputsProps): ReactElement => {
  return (
    <Switcher
      className={cn('donate-inputs', className)}
      classNameTabs={cn('donate-inputs__tabs')}
      tabs={[
        <SwitcherTab key='boosty'>Boosty</SwitcherTab>,
        <SwitcherTab key='btc'>Bitcoin</SwitcherTab>,
      ]}
    >
      <SwitcherContent>
        <Paragraph align='center' color='blue-1'>
          Помогите нам помогать: подпишитесь на регулярные пожертвования на
          сервисе Boosty (от 150 ₽ в месяц).
        </Paragraph>
        <Paragraph align='center' color='blue-1'>
          <a
            className='donate-inputs__link'
            href='https://boosty.to/fortress'
            target='_blank'
            rel='noopener noreferrer'
          >
            https://boosty.to/fortress
          </a>
        </Paragraph>
      </SwitcherContent>
      <SwitcherContent>
        <Paragraph align='center' color='blue-1'>
          Мы принимаем пожертвования в криптовалюте!
        </Paragraph>
        <CopyElement title='Bitcoin' theme={theme}>
          {Requisites.BTC}
        </CopyElement>
        <CopyElement title='Bitcoin (segwit)' theme={theme}>
          {Requisites.BTC_SEGWIT}
        </CopyElement>
      </SwitcherContent>
    </Switcher>
  )
}
