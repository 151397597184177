import classNames from 'classnames'
import React, {
  ReactElement,
  useCallback,
  MouseEvent,
  KeyboardEvent,
} from 'react'
import Close from 'app/images/close.svg'
import './modal.scss'

interface ModalProps {
  children: ReactElement | ReactElement[]
  onClose: () => void
  isOpen: boolean
}

export const Modal = ({
  children,
  onClose,
  isOpen,
}: ModalProps): ReactElement => {
  const handlePropagation = useCallback((e: MouseEvent) => {
    e.stopPropagation()
  }, [])

  const onEsc = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    },
    [onClose, isOpen]
  )

  return (
    <div
      tabIndex={-1}
      role='dialog'
      className={classNames('modal', {
        'modal--hidden': !isOpen,
      })}
      aria-hidden='true'
      onClick={onClose}
      onKeyDown={onEsc}
    >
      <div
        role='presentation'
        className='modal__content'
        onClick={handlePropagation}
        aria-label='close modal'
      >
        <button
          className='modal__close'
          onClick={onClose}
          aria-label='close modal'
        >
          <Close />
        </button>
        {children}
      </div>
    </div>
  )
}
